import { AXIOS } from "utils/axios";
import { store } from "redux-store/store";
import {
  clearAddDiagnosis,
  clearIdParam,
  clearNewRxDetail,
  getIdParam,
  getMedicines,
  getRxUsers,
  getSearchRxuser,
  getSingleRxUsers,
  getTotalCount,
  getUser,
  particularUserPrescription,
  rxHistory,
  updateMedicinesData,
  clearDiagnosticsPrescriptionsDetails,
  getPdfUrl,
} from "redux-store/slices/user";
import { setLoading } from "redux-store/slices/loading";
import { toast } from "react-toastify";
import { router } from "App";
import { convertToAMPM } from "utils/helper";
import waitingList from "services/waitingList";

class UserService {
  async editProfile(values, id, otp) {
    try {
      store.dispatch(setLoading(["Edit_Profile", true]));
      const {
        LicenseNumber,
        deegree,
        fullName,
        location,
        mobileNumber,
        address,
        timing,
      } = values;
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.post(
        "/edit-user",
        {
          id: id,
          full_name: fullName,
          licence_number: LicenseNumber,
          degree_speciality: deegree,
          practice_location: location,
          mobile_number: mobileNumber,
          contact_info: address,
          otp: otp,
          timings: timing,
        },
        config
      );

      if (response.status === 200) {
        toast.success(response?.data?.message);
      }
      // if(response.)
    } catch (error) {
      toast.error(error?.response?.data?.message);

      // Handle error
    } finally {
      store.dispatch(setLoading(["Edit_Profile", false]));
    }
  }

  async getUser(id) {
    try {
      store.dispatch(setLoading(["PAGE_LOADING", true]));
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.get(`/view-user?id=${id}`, config);
      if (response?.data?.statusCode === 200) {
        store.dispatch(getUser(response?.data?.body?.user));
      }
    } catch (error) {
    } finally {
      store.dispatch(setLoading(["PAGE_LOADING", false]));
    }
  }
  async searchUser(key, value) {
    store.dispatch(setLoading(["SEARCH_RX", true]));
    try {
      // store.dispatch(setLoading(["PAGE_LOADING", true]));
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`, // Include the token in the Authorization header
        },
      };
      const response = await AXIOS.get(`/rx-user?${key}=${value}`, config);
      if (response?.data?.statusCode === 200) {
        if (key === "id") {
          store.dispatch(getSingleRxUsers(response?.data?.body?.rx_users));
        } else {
          store.dispatch(getRxUsers(response?.data?.body?.rx_users));
        }
      }
      return response
    } catch (error) {
    } finally {
      store.dispatch(setLoading(["SEARCH_RX", false]));
    }
    
  }
async lastTenRx(){
  try {
    const config = {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    };
    const response = await AXIOS.get('/rx-History',config)
    if(response?.data?.status){
     return  response
    }
  } catch (error) {
    return error
  }
}
  async searchRxUser(data) {
    store.dispatch(setLoading(["SEARCHRX_USER", true]));

    try {
      const { phoneNumber, age, patientName, gender } = data || {};
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.post(
        "/rx-user",
        {
          mobile_number: phoneNumber,
          patient_name: patientName,
          age: age,
          gender: gender,
        },
        config
      );
      if (response?.data?.statusCode === 200) {
        router.navigate(
          `/rx-history?memberId=${response?.data?.body?.rx_user?.id}`
        );
        store.dispatch(getIdParam(response?.data?.body?.rx_user?.id));
      }
    } catch (error) {
      console.log("");
    }
  }

  async searchRx(value) {
    store.dispatch(setLoading(["SEARCH_RX", true]));
    try {
      // store.dispatch(setLoading(["PAGE_LOADING", true]));
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`, // Include the token in the Authorization header
        },
      };
      const response = await AXIOS.get(`/rx-user?search=${value}`, config);
      if (response?.data?.statusCode === 200) {
        store.dispatch(getSearchRxuser(response?.data?.body?.rx_users));
      }
    } catch (error) {
    } finally {
      store.dispatch(setLoading(["SEARCH_RX", false]));
    }
  }

  async rxUser(data) {
    store.dispatch(setLoading(["Rx_USER", true]));

    try {
      const { phoneNumber, age, patientName, gender } = data || {};
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.post(
        "/rx-user",
        {
          mobile_number: phoneNumber,
          patient_name: patientName,
          age: age,
          gender: gender,
        },
        config
      );
      if (response?.data?.statusCode === 200) {
        router.navigate(`/new-rx/${response?.data?.body?.rx_user?.id}`);
        store.dispatch(getIdParam(response?.data?.body?.rx_user?.id));
      }
      return response
    } catch (error) {
      console.log("");
    } finally {
      store.dispatch(setLoading(["Rx_USER", false]));
    }
  }

  async searchMedicine(data, page) {
    store.dispatch(setLoading(["SEARCH_MEDICINES", true]));

    try {
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.get(
        `/get-medicines?search=${data}&page=${page}`,
        config
      );
      if (response?.data.statusCode === 200) {
        store.dispatch(
          updateMedicinesData({ medicines: response?.data?.medicines })
        );
        store.dispatch(getTotalCount(response?.data?.totalCount));
      }
      return {
        data: response?.data?.medicines,
        count: response?.data?.totalCount,
      };
      // store.dispatch(updateMedicinesData(response));
    } catch (error) {
      store.dispatch(updateMedicinesData({ shouldReset: true }));
    } finally {
      store.dispatch(setLoading(["SEARCH_MEDICINES", false]));
    }
  }

  async editMobileNumber(otp, mobile_number, newMobileNumber) {
    store.dispatch(setLoading(["confirm_OTP", true]));

    try {
      const data = {
        otp: otp,
        mobile_number: mobile_number,
        new_number: newMobileNumber,
      };
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.post("verify-mobile", data, config);
      if (response.data.statusCode === 200) {
        toast.success(response?.data?.message);
        return true;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return false;
      // throw error;
    } finally {
      store.dispatch(setLoading(["confirm_OTP", false]));
    }
  }
  async addPrescription(prescription, isPrint, isMobile) {
    isPrint
      ? store.dispatch(setLoading(["PRINT_PDF", true]))
      : store.dispatch(setLoading(["ADD_PRESCRIPTION", true]));
    try {
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.post("prescription", prescription, config);
      if (response?.data?.statusCode === 200) {
        const waitingListId = localStorage.getItem("selectedUserId");
        await waitingList.remoeUserFromWaitingList(waitingListId);
        localStorage.removeItem("selectedUserId");
        store.dispatch(clearIdParam());
        store.dispatch(clearNewRxDetail());
        store.dispatch(clearAddDiagnosis());
        store.dispatch(clearDiagnosticsPrescriptionsDetails());
        if (response.data.url) {
          isMobile
            ? window.open(response.data.url, "_self")
            : store.dispatch(getPdfUrl(response.data.url));
        }
        toast.success(response?.data?.message);
 router.navigate("/home");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      store.dispatch(setLoading(["ADD_PRESCRIPTION", false]));
      store.dispatch(setLoading(["PRINT_PDF", false]));
    }
  }
  async getRxHistory(id) {
    store.dispatch(setLoading(["GET_RX_HISTORY", true]));

    // https://62kutcgk3g.execute-api.us-east-1.amazonaws.com/production/prescription?user_id=1
    try {
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.get(`/prescription?user_id=${id}`, config);
      if (response?.data?.statusCode === 200) {
        store.dispatch(rxHistory(response?.data));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      store.dispatch(setLoading(["GET_RX_HISTORY", false]));
    }
  }

  async getParticularUserPrescription(user_id, id) {
    store.dispatch(setLoading(["GET_PARTICULAR_USER_PRESCRIPTION", true]));

    try {
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.get(
        `prescription?user_id=${user_id}&prescription_id=${id}`,
        config
      );
      if (response?.data?.statusCode === 200) {
        store.dispatch(particularUserPrescription(response.data));
      }
    } catch (error) {
      console.log(error);
    } finally {
      store.dispatch(setLoading(["GET_PARTICULAR_USER_PRESCRIPTION", false]));
    }
  }
  //

  async addMedicine(values) {
    store.dispatch(setLoading(["ADD_MEDICINE_LOADING", true]));

    try {
      store.dispatch(setLoading(["ADD_MEDICINE_LOADING", true]));
      const {
        brand_name_short,
        dosage_duration,
        intake_morning,
        additional_instruction,
        intake_afternoon,
        intake_evening,
        intake_night,
        before_food,
        after_food,
      } = values;
      const config = {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      };
      const response = await AXIOS.post(
        "/get-medicines",
        {
          brand_name_short: brand_name_short,
          dosage_duration: dosage_duration,
          additional_instruction: additional_instruction,
          intake_morning: intake_morning,
          intake_afternoon: intake_afternoon,
          intake_evening: intake_evening,
          intake_night: intake_night,
          before_food: before_food,
          after_food: after_food,
        },
        config
      );

      if (response.status === 200) {
        // toast.success(response?.data?.message);
      }
      // if(response.)
    } catch (error) {
      toast.error(error?.response?.data?.message);

      // Handle error
    } finally {
      store.dispatch(setLoading(["ADD_MEDICINE_LOADING", false]));
    }
  }
}
export default new UserService();
