import React, { useEffect } from "react";
import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import Container from "react-bootstrap/Container";
import { router } from "App";
import ClockIcon from "assets/svg/ClockIcon";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import user from "services/user";
import { convertTimestampToReadableDate } from "utils/helper";
import Loader from "components/loader/Loader";
import { getsearchIdParam } from "redux-store/slices/user";
import { store } from "redux-store/store";

export default function RxHistory() {
  const { rxHistory } = useSelector((state) => state.user);
  const { rx_user, prescription } = rxHistory;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const memberId = searchParams.get("memberId");
  useEffect(() => {
    user.getRxHistory(memberId);
  }, []);
  const { GET_RX_HISTORY: getRxHistoryLoading } = useSelector(
    (state) => state.loading
  );
  useEffect(() => {
    const data = {
      pathName: location.pathname,
      memberId: memberId,
    };
    store.dispatch(getsearchIdParam(data));
  }, []);
  return (
    <>
      <Container className="mt-20 p-0">
        <div style={{ padding: "0px 16px" }}>
          <Mobileheader
            pageName="Rx History "
            onClick={() => router.navigate("/search-rx")}
          />
        </div>
        {getRxHistoryLoading ? (
          <div className="mt-4">
            <Loader />
          </div>
        ) : (
          <Container className="mt-28 mb-4" style={{ padding: "0px" }}>
            <div
              className="d-flex justify-content-between Small 
 shadow"
              style={{
                width: "90%",
                margin: "20px auto",
                border: "1px solid #f1f1f2",
                padding: "12px",
                borderRadius: "10px",
              }}
            >
              <div>
                <p className="m-0 fw-700 fs-24">{rx_user?.patient_name}</p>
                <p className="m-0 fw-400 fs-16 grey">
                  {" "}
                  {rx_user?.gender[0]?.toUpperCase()}
                  {rx_user?.gender.slice(1)} /&nbsp;
                  {rx_user?.age} Years
                </p>
              </div>
              <div>
                <p className="fw-700 fs-16">
                  Rx ID &nbsp;
                  <span className="grey fw-400 fs-14">
                    {prescription?.[0]?.rx_id}
                  </span>
                </p>
              </div>
            </div>
            <div
              className="d-flex gap-3 flex-wrap small shadow
"
              style={{
                width: "90%",
                margin: "auto",
                border: "2px solid #f0f0f1",
                borderRadius: "10px",
                padding: "28px 16px",
                maxHeight: "545px",
                overflow: "auto",
                scrollbarGutter: "stable",
              }}
            >
              {!getRxHistoryLoading && prescription?.length === 0 && (
                <div className="text-center fw-700 fs-26 w-100">
                  Data Not Found
                </div>
              )}
              {prescription?.map((item, index) => {
                return (
                  <>
                    <div
                      style={{
                        borderRadius: "10px",
                        padding: "18px 11px",
                        // background: index % 2 === 0 ? "#fbfbfb" : "#fffaf1",
                        background: index % 2 === 0 ? "#f3fbfe" : "#fff5f4",
                        // background: "#f3fbfe",
                        border: "1px solid #e3eaee",
                        minWidth: "100%",
                      }}
                      onClick={() =>
                        router.navigate(
                          `/prescription?user_id=${item.user_id}?&prescription_id=${item.id}`
                        )
                      }
                    >
                      <span className="fs-14 fw-700 m-0">
                        {item?.user?.full_name}
                      </span>
                      <span className="fs-14 fw-400 grey ml-6">
                        {item?.user?.degree_speciality}
                      </span>
                      <div className="d-flex ">
                        <span
                          className="text-center fw-700"
                          style={{
                            display: "inline-block",
                            width: "48px",
                            backgroundColor: "#ceeefa",

                            fontSize: "12px",
                            padding: "8px",
                            borderRadius: "6px",
                          }}
                        >
                          {convertTimestampToReadableDate(
                            item?.prescription_medicines?.[0]?.createdAt
                          )}
                        </span>
                        <span
                          className="fs-12  ml-6"
                          style={{ color: "#253274" }}
                        >
                          {item?.follow_up_advice}
                        </span>
                      </div>
                      <div className="mt-20">
                        <p className="fw-500 fs-14 m-0 ">
                          <p className="fw-500 fs-14 m-0 ">
                            {`${item.prescription_medicines?.[0]?.medicine?.brand_name_short} ( ${item.prescription_medicines?.[0]?.medicine?.generic_name})`}
                          </p>
                        </p>
                      </div>
                      <span
                        className="fs-12 mt-15"
                        style={{ color: "#999999" }}
                      >
                        <ClockIcon /> &nbsp;{" "}
                        {item?.prescription_medicines?.[0]?.dosage_duration &&
                          `  (${item?.prescription_medicines?.[0]?.dosage_duration})`}
                        <span className="fw-700 fs-12">
                          {(item?.prescription_medicines?.[0]
                            ?.intake_morning === 1 ||
                            item?.prescription_medicines?.[0]
                              ?.intake_afternoon === 1 ||
                            item?.prescription_medicines?.[0]
                              ?.intake_evening === 1 ||
                            item?.prescription_medicines?.[0]?.intake_night ===
                              1) && (
                            <>
                              <span style={{ color: "#999999" }}>
                                (
                                <span
                                  className={`${"fs-12"} fw-400`}
                                  style={{ color: "#999999" }}
                                >
                                  {[
                                    item?.prescription_medicines?.[0]
                                      ?.intake_morning && "Morning",
                                    item?.prescription_medicines?.[0]
                                      ?.intake_afternoon && "Afternoon",
                                    item?.prescription_medicines?.[0]
                                      ?.intake_evening && "Evening",
                                    item?.prescription_medicines?.[0]
                                      ?.intake_night && "Night",
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                                </span>
                                )
                              </span>
                            </>
                          )}
                          <span className="fw-400" style={{ color: "#999999" }}>
                            (
                            {item?.prescription_medicines?.[0]?.before_food ===
                            1
                              ? "Before food"
                              : "After food"}
                            )
                          </span>
                        </span>
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
          </Container>
        )}
      </Container>
    </>
  );
}
