import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  Modal,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { addPrescriptionDetails } from "redux-store/slices/user";
import { store } from "redux-store/store";
import { useParams } from "react-router-dom";
import favourite from "services/favourite";
import { toast } from "react-toastify";
import TrashIcon from "assets/svg/TrashIcon";

const ModalBox = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const { id } = useParams();
  const [selectedFavouriteMedicine, setSelectedFavouriteMedicine] = useState(
    []
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Initialize filtered data with the full list of favorite medicines from props
    setFilteredData(props.favMedData);
  }, [props.favMedData]);
  const handleCheckboxChange = (e, item) => {

    const isChecked = e.target.checked;
    if (isChecked) {
      if (item?.med_data_json.length === 1) {
        setSelectedItems([...selectedItems, item]);
      }
      if (item.med_data_json.length > 0) {
        setSelectedFavouriteMedicine([
          ...selectedFavouriteMedicine,
          { medicine_id: item.medicine_id, med_data_json: item.med_data_json },
        ]);
      }
    } else {
      setSelectedFavouriteMedicine((prevFavouriteMedicines) =>
        prevFavouriteMedicines.filter((i) => i.medicine_id !== item.medicine_id)
      );
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((i) => i.id !== item.id)
      );
    }
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    // Filter the data based on the search term
    const filtered = props.favMedData.filter((item) =>
      item.brand_name.toLowerCase().includes(searchValue)
    );
    setFilteredData(filtered);
  };

  const addDataToRX = () => {
    selectedItems.forEach((item) => {
      const data = {
        id:item?.id,
        medicine_id: item?.medicine_id,
        brand_name:item.brand_name,
        brand_name_short: `${item.brand_name} ${item.generic_name? `(${item.generic_name})`:''}`,
        generic_name:item.generic_name,
        medicine_name: `${item.brand_name} (${item.generic_name})`,
        dosage_duration: item.med_data_json[0].dosage_duration,
        additional_instruction: item?.med_data_json[0].additional_instruction,
        intake_morning: item?.med_data_json[0].intake_morning,
        intake_afternoon: item?.med_data_json[0].intake_afternoon,
        intake_evening: item?.med_data_json[0].intake_evening,
        intake_night: item?.med_data_json[0].intake_night,
        before_food: item?.med_data_json[0].before_food,
        after_food: item?.med_data_json[0].after_food,
        is_diagnostic: 0,
        fav: 1,
      };
      store.dispatch(
        addPrescriptionDetails({
          id: id,
          diagnosis: "",
          medicalDetail: data,
        })
      );
    });
    props.onHide();
  };

  const handleDeleteFav = () => {

    const selectedId = selectedItems.map((item) => item.id);
    if (selectedId.length <= 0) {
      toast.warning("Please Select medicine you want to delete ");
      return;
    }
    selectedItems.forEach((item) => {
      props.setSelectedIndexes((prevIndexes) => {
        return prevIndexes.filter((i) => i !== item.medicine_id);
      });
    });
    if(selectedItems.length===1){

      favourite.deleFavouriteItem(selectedId,selectedItems[0].med_data_json[0].dosage_duration);
    }else{

      favourite.deleFavouriteItem(selectedId);
    }
    props.onHide();
  };

  const addSelectedVariant = (e, item, med) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const obj = {
        id: item?.id,
        medicine_id: item?.medicine_id,
        brand_name: item?.brand_name,
        generic_name: item?.generic_name,
        doctor_id: item?.doctor_id,
        createdAt: item?.createdAt,
        updatedAt: item?.updatedAt,
        med_data_json: [med],
      };

      setSelectedItems((prevSelectedItems) => {
        const filteredItems = prevSelectedItems.filter((i) => i.id !== item.id);
        return [...filteredItems, obj];
      });
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((i) => i.id !== item.id)
      );
    }
  };

  useEffect(() => {
    setSelectedItems([]);
  }, [props.show]);
  const generateLabel = (detail) => {
    return (
      <>
        {detail.dosage_duration}
        {(detail?.intake_morning === 1 ||
          detail?.intake_afternoon === 1 ||
          detail?.intake_evening === 1 ||
          detail?.intake_night === 1) && (
          <>
            <span style={{ color: "#5e5992" }}>
              (
              <span className={` "fs-16"} fw-400`} style={{ color: "#5e5992" }}>
                {[
                  detail?.intake_morning && "Morning",
                  detail?.intake_afternoon && "Afternoon",
                  detail?.intake_evening && "Evening",
                  detail?.intake_night && "Night",
                ]
                  .filter(Boolean)
                  .join(", ")}
              </span>
              )
            </span>
          </>
        )}
        <span className={`"fs-16"} fw-400`} style={{ color: "#5e5992" }}>
          {detail?.additional_instruction?.length > 0 && (
            <span>({detail?.additional_instruction})</span>
          )}
        </span>
      </>
    );
  };

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop
      className="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Favourite Medicine List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="gap-2 nav-stacked custom-modal-body">
        <Container style={{ overflowY: "auto", height: "66vh" }}>
          <input
            type="text"
            id="searchInput"
            className="form-control mb-3"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {filteredData?.length > 0 ? (
            filteredData
              .sort((a, b) => a.brand_name.localeCompare(b.brand_name))
              .map((item) => (
                <Row key={item.id} className="mb-3">
                  <Col
                    xs={12}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        id={item.id}
                        name="formHorizontalRadios"
                        className="formRadios"
                        value={item.brand_name}
                        checked={selectedItems.some((i) => i.id === item.id)}
                        onChange={(e) => handleCheckboxChange(e, item)}
                      />
                      <label
                        htmlFor={item.id}
                        className="ms-2 grey custom-label"
                      >
                        {item.brand_name}
                      </label>
                    </div>
                  </Col>
                  {item?.med_data_json.length > 1 &&
                    selectedFavouriteMedicine.some(
                      (favItem) => favItem.medicine_id === item.medicine_id
                    ) && (
                      <Col
                        xs={11}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                        className="offset-1 mt-2"
                      >
                        {item.med_data_json.map((detail, index) => (
                          <Form.Check
                            key={`${item.medicine_id}-${index}`}
                            type="radio"
                            id={`${item.medicine_id}-${index}`}
                            name="nestedRadiosBox"
                            label={generateLabel(detail)}
                            className="ms-3 custom-nested-checkbox"
                            onChange={(e) =>
                              addSelectedVariant(e, item, detail)
                            }
                          />
                        ))}
                      </Col>
                    )}
                </Row>
              ))
          ) : props.showLoading ? (
            <center>Loading.....</center>
          ) : (
            <center>No Data Found</center>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <div
          style={{ width: "100%", position: "relative" }}
          className="d-flex justify-content-center gap-4 "
        >
          <div>
            <Button onClick={addDataToRX}>Add to Rx</Button>
          </div>
          <div
            onClick={handleDeleteFav}
            style={{ right: "10px", position: "absolute", cursor: "pointer" }}
          >
            <TrashIcon />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBox;
