import EditIcon from "assets/svg/EditIcon";
import Plus from "assets/svg/Plus";
import TrashIcon from "assets/svg/TrashIcon";
import UtilityButton from "components/buttons/UtilityButton";
import DeleteModal from "components/onboard-assistant/deleteModal";
import OnboardAssistantModal from "components/onboard-assistant/onboardAssistantModal";
import DataBox from "components/shared/dataBox";
import PageHeading from "components/shared/pageHeading";
import SectionContainer from "components/shared/sectionContainer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addAssistant, emptyAssistantList } from "redux-store/slices/assistant";
import { store } from "redux-store/store";
import assistant from "services/assistant";

const Assistant = () => {
  const AssistantDetail = useSelector((state) => state.assistant);
  const [modalShow, setModalShow] = React.useState(false);
  const [assistantData, setAssistantData] = React.useState({});
  const [loading, setLoading] = useState(true);
  const [DeleteModalShow, setDeleteModalShow] = useState(false);
  const { assistants } = AssistantDetail || {};
  const handleEditAssistant = (item) => {
    setModalShow(true);
    setAssistantData({
      name: item?.name,
      mobileNo: item?.phoneNumber,
      id: item?.id,
      assistantId:item?.assistant_id
    });
  };
  const fetchAssistantDetail = async () => {
    try {
      store.dispatch(emptyAssistantList());
      const data = await assistant.getAssistant();
      if (data?.data?.status) {
        setLoading(false);
        data?.data?.listOfAssistants.forEach((item) => {
          store.dispatch(
            addAssistant({
              name: item?.name,
              phoneNumber: item?.mobile_number,
              id: item?.id,
              assistant_id:item?.assistant_id
            })
          );
        });
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchAssistantDetail();
  }, []);
  return (
    <SectionContainer>
      <PageHeading heading="Assistant List" />
      <div
        className="d-flex justify-content-end align-items-center "
        style={{ width: "90%", margin: "0px auto 30px" }}
      >
        <div>
          <UtilityButton
            btnName={"Add Assistant"}
            onClick={() => {
              setModalShow(true);
            }}
            width="190px"
            height="45px"
            suffix={<Plus />}
            disabled={assistants.length === 3 ? true : false}
          />
          <OnboardAssistantModal
            show={modalShow}
            onHide={() => {
              setAssistantData({});
              setModalShow(false);
            }}
            AssistantData={assistantData}
          />
        </div>
      </div>
      <DataBox>
        <div style={{ paddingTop: "20px" }}>
          {loading ? (
            <center>Loading ....</center>
          ) : !loading && assistants.length <= 0 ? (
            <center>No Assistant Added </center>
          ) : (
            assistants.map((item, index) => {
              return (

                <DataBox key={index} paddingBottom={"20px"}>
                  <div
                    className="d-flex align-items-center justify-content-between "
                    style={{ padding: "0px 20px" }}
                  >
                    <div style={{ padding: "20px 30px 0px" }}>
                      <h5 style={{ color: "#0c52a8" }}>
                        {" "}
                        <span style={{ color: "#1b263b" }}>Name:</span>{" "}
                        {item?.name}
                      </h5>
                      <p style={{ margin: 0, color: "#0c52a8" }}>
                        {" "}
                        <span style={{ color: "#1b263b" }}>
                          Mobile No:
                        </span>{" "}
                        {item?.phoneNumber}
                      </p>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          handleEditAssistant(item);
                        }}
                      >
                        {" "}
                        <EditIcon />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setDeleteModalShow(true);
                          setAssistantData(item);
                        }}
                      >
                        {" "}
                        <TrashIcon />
                      </div>
                    </div>
                    <DeleteModal
                      show={DeleteModalShow}
                      onHide={() => {
                        setDeleteModalShow(false);
                        setAssistantData({});
                      }}
                      assistantData={assistantData}
                    />
                  </div>
                </DataBox>
              );
            })
          )}
        </div>
      </DataBox>
    </SectionContainer>
  );
};

export default Assistant;
