import useIsMobile from "hooks/useResize";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import UtilityButton from "components/buttons/UtilityButton";
import * as formik from "formik";
import { drugModalSchema } from "utils/schema/DrugModalSchema";
import {
  addDiagnosis,
  addPrescriptionDetails,
  deletePrescriptionsDetails,
  editPrescriptionDetails,
  addDiagnosticPrescriptionDetails,
  updateMedicine,
  editDiagnosticPrescriptionDetails,
  deleteDiagnosticPrescriptionsDetails,
} from "redux-store/slices/user";
import { store } from "redux-store/store";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function DrugModal({
  isOpen,
  medicineDetail,
  setIsOpen,
  diagnosis,
  isEdit,
  setIsEdit,
  isDiagnostics,
  setIsDiagnostics,
  medInstruction,
  isOpenAddDrugModal,
  setInputModal,
  setInputValue,
  // setIsOpenAddDrugModal,
}) {
  const [editData, setEditData] = useState([]);
  const [radioButton, setRadioButton] = useState("medicines");
  const { newRx, medicineId } = useSelector((state) => state.user);
  const { id } = useParams();
  const { prescriptions } = newRx?.[id] || {};
  const { Formik } = formik;
  const handleClose = () => {
    setIsOpen(false);
    // setIsOpenAddDrugModal(false)
    setIsOpen(false);
    setIsEdit(false);

    // setIsDiagnostics(false)
    store.dispatch(updateMedicine());
  };

  const isMobile = useIsMobile();
  useEffect(() => {
    const data = prescriptions?.filter((item, index) => index === medicineId);
    setEditData(data);
  }, [medicineId]);

  const handleDeleteMedicine = () => {
    if (isDiagnostics === true) {
      store.dispatch(
        deleteDiagnosticPrescriptionsDetails({
          id,
          medicineId: medInstruction.index,
        })
      );
    } else {
      store.dispatch(deletePrescriptionsDetails({ id, medicineId }));
    }
    setIsEdit(false);
    setIsOpen(false);
    setIsDiagnostics(false);
  };
  const handleRadioChnage = (e, setFieldValue) => {
    setFieldValue("brand_name_short", "");
    setFieldValue("dosage_duration", "");
    setFieldValue("additional_instruction", "");

    setRadioButton(e.target.id);
  };
  let textIndex = medicineDetail && medicineDetail?.label?.indexOf("(");
  return (
    <Modal
      show={isOpen}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      onHide={handleClose}
    >
      <Modal.Header
        className="d-flex justify-content-center border-0"
        closeButton
      >
        <Modal.Title>
          <p
            className={`d-flex ${
              !isMobile ? "text-center fs-26" : "fs-20"
            } fw-500 p-0 m-0`}
            style={{ color: "#253274" }}
          >
            {/* {isOpenAddDrugModal && radioButton === "diagnostics " ? "Manual Entry" : "Manual Entry/ Diagnostics"} */}

            {isEdit
              ? ` ${isDiagnostics ? "Edit Diagnostics" : "Edit  Medicine"}`
              : ` ${
                  radioButton === "diagnostics" || isDiagnostics
                    ? "Add Diagnostics"
                    : "Add  Medicine"
                }`}
            {/* {isEdit ? `Edit  Medicine ${isDiagnostics ? "/ Diagnostics" : ""}` : `${isOpenAddDrugModal ? "Manual Entry" : "Add  Medicine"}  ${(radioButton === "diagnostics" || isDiagnostics) ? "/ Diagnostics" : ""}`} */}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={drugModalSchema(
          isOpenAddDrugModal,
          radioButton === "diagnostics",
          isDiagnostics
        )}
        onSubmit={(values) => {
          const formData = {
            brand_name: medicineDetail.brand_name,
            brand_name_short:
              values.brand_name_short ||
              medicineDetail.brand_name_short ||
              medicineDetail.label,
            medicine_name:
              medicineDetail?.label || medicineDetail.brand_name_short,
            medicine_id: medicineDetail?.value,
            dosage_duration: values.dosage_duration,
            intake_morning: values.intake_morning === true ? 1 : 0,
            intake_afternoon: values.intake_afternoon === true ? 1 : 0,
            intake_evening: values.intake_evening === true ? 1 : 0,
            intake_night: values.intake_night === true ? 1 : 0,
            before_food: values.food_option === "before_food" ? 1 : 0,
            after_food: values.food_option === "after_food" ? 1 : 0,
            additional_instruction: values.additional_instruction,
            generic_name: medicineDetail?.generic_name,
          };

          const ManualEntryData = {
            brand_name_short: values.brand_name_short,
            dosage_duration: values.dosage_duration,
            brand_name:values.brand_name_short,
            additional_instruction: values.additional_instruction,
            intake_morning: values.intake_morning === true ? 1 : 0,
            intake_afternoon: values.intake_afternoon === true ? 1 : 0,
            intake_evening: values.intake_evening === true ? 1 : 0,
            intake_night: values.intake_night === true ? 1 : 0,
            before_food: values.food_option === "before_food" ? 1 : 0,
            after_food: values.food_option === "after_food" ? 1 : 0,
            is_diagnostic: radioButton === "diagnostics" ? 1 : 0,
          };
          const diagnosticData = {
            brand_name_short:
              values.brand_name_short || medInstruction?.brandShortName,
            additional_instruction: values.additional_instruction,
            is_diagnostic: radioButton === "diagnostics" ? 1 : 0,
            medicine_id: medicineDetail?.value,
          };

          setInputValue("");
          if (isEdit) {
            setIsEdit(true);
            store.dispatch(addDiagnosis(diagnosis));
            if (isDiagnostics === true) {
              store.dispatch(
                editDiagnosticPrescriptionDetails({
                  id: id,
                  index: medInstruction?.index,
                  formData: {
                    ...diagnosticData,
                    additional_instruction: values.additional_instruction,
                    brand_name_short: medInstruction?.brandShortName,
                  },
                })
              );
            } else {
              store.dispatch(
                editPrescriptionDetails({
                  id: id,
                  index: medicineDetail.index,
                  formData: {
                    ...formData,
                    brand_name_short: medicineDetail.brand_name_short,
                  },
                })
              );
            }
          } else if (isOpenAddDrugModal || medicineDetail.is_diagnostic === 1) {
            if (
              radioButton === "diagnostics" ||
              medicineDetail.is_diagnostic === 1
            ) {
              store.dispatch(
                addDiagnosticPrescriptionDetails({
                  id: id,
                  diagnosis: diagnosis,
                  medicalDetail: diagnosticData,
                })
              );
            } else {
              if (medicineDetail.is_diagnostic !== 1) {
                store.dispatch(
                  addPrescriptionDetails({
                    id: id,
                    diagnosis: diagnosis,
                    medicalDetail: ManualEntryData,
                  })
                );
              }
            }
          } else {
            store.dispatch(
              addPrescriptionDetails({
                id: id,
                diagnosis: diagnosis,
                medicalDetail: formData,
              })
            );
          }

          setIsEdit(false);
          setIsOpen(false);
          // setIsOpenAddDrugModal(false)
          setIsDiagnostics(false);
          setInputModal("");

          store.dispatch(updateMedicine());
        }}
        initialValues={{
          brand_name_short: "",
          diagnostics: "",
          dosage_duration: isEdit ? editData?.[0]?.dosage_duration : "",
          intake_morning: isEdit
            ? editData?.[0]?.intake_morning === 1
              ? true
              : false
            : false,
          intake_afternoon: isEdit
            ? editData?.[0]?.intake_afternoon === 1
              ? true
              : false
            : false,
          intake_evening: isEdit
            ? editData?.[0]?.intake_evening === 1
              ? true
              : false
            : false,
          intake_night: isEdit
            ? editData?.[0]?.intake_night === 1
              ? true
              : false
            : false,

          food_option: isEdit
            ? editData?.[0]?.before_food === 1
              ? "before_food"
              : "" || editData?.[0]?.after_food === 1
              ? "after_food"
              : ""
            : "",

          additional_instruction: isEdit
            ? isDiagnostics
              ? medInstruction.instructions
              : editData?.[0]?.additional_instruction
            : "",
        }}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form formNoValidate="formnovalidate" onSubmit={handleSubmit}>
            <Modal.Body
              className="d-flex justify-content-center text-center 
               border-0 p-0"
            >
              <div style={{ width: isMobile ? "90%" : "70%" }}>
                {isOpenAddDrugModal ? (
                  <>
                    <div className="d-flex gap-5">
                      <div class="form-check d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="medicines"
                          onChange={(e) => handleRadioChnage(e, setFieldValue)}
                          checked={radioButton === "medicines"}
                        />
                        <label
                          className="form-check-label fs-18"
                          for="medicines"
                        >
                          Medicines
                        </label>
                      </div>

                      <div className="form-check d-flex align-items-center ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="diagnostics"
                          onChange={(e) => handleRadioChnage(e, setFieldValue)}
                          checked={radioButton === "diagnostics"}
                        />
                        <label
                          className="form-check-label fs-18"
                          for="diagnostics"
                        >
                          Diagnostics
                        </label>
                      </div>
                    </div>

                    <div className="textAreaBox">
                      <textarea
                        style={{ height: "74px" }}
                        type="text"
                        class="form-control mt-36"
                        id="exampleInputPassword1"
                        name="brand_name_short"
                        value={values.brand_name_short}
                        placeholder={
                          radioButton === "diagnostics" || isDiagnostics
                            ? "Name of the Test (Required)"
                            : "Brand or Generic Medicine (Required)"
                        }
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <span className="d-flex red text-align-start">
                      {touched.brand_name_short && errors.brand_name_short}
                    </span>
                  </>
                ) : (
                  <p
                    className={`fw-700  m-0 ${
                      !isMobile ? "text-center fs-26" : "fs-20"
                    }`}
                  >
                    <>
                      {isDiagnostics ? (
                        
                        medInstruction?.brandShortName
                      ) : medicineDetail?.generic_name === undefined ? (
                        medicineDetail?.label
                      ) : (
                        <>
                          {medicineDetail?.label.slice(0, textIndex)}
                          <span className="fw-400">
                            {medicineDetail?.label.slice(textIndex)}
                          </span>
                        </>
                      )}
                    </>
                  </p>
                )}
                {radioButton === "medicines" && !isDiagnostics && (
                  <div className="textAreaBox">
                    <textarea
                      style={{ height: "74px" }}
                      type="text"
                      class="form-control mt-36"
                      id="exampleInputPassword1"
                      name="dosage_duration"
                      value={values.dosage_duration}
                      placeholder="Dosage and Duration (Required)"
                      onChange={handleChange}
                      required
                    />
                  </div>
                )}
                {radioButton !== "diagnostics" && (
                  <span className="d-flex red text-align-start">
                    {touched.dosage_duration && errors.dosage_duration}
                  </span>
                )}
                {radioButton === "medicines" && !isDiagnostics && (
                  <div
                    className={`d-flex  ${
                      isMobile ? "justify-content-between" : "flex-column"
                    } mt-20`}
                  >
                    <div
                      className={`fs-14 ${
                        !isMobile && "d-flex justify-content-between    "
                      } `}
                    >
                      <div className="d-flex mb-8">
                        <Form.Check
                          inline
                          // label="Morning"
                          type="checkbox"
                          id="intake_morning_checkbox"
                          name="intake_morning"
                          checked={values.intake_morning}
                          onChange={handleChange}
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        />
                        <label
                          htmlFor="intake_morning_checkbox"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        >
                          Morning
                        </label>
                      </div>
                      <div className="d-flex mb-8">
                        <Form.Check
                          inline
                          name="intake_afternoon"
                          id="intake_afternoon_checkbox"
                          checked={values.intake_afternoon}
                          onChange={handleChange}
                          type="checkbox"
                          className={`d-flex  align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        />
                        <label
                          htmlFor="intake_afternoon_checkbox"
                          className={`d-flex align-items-center ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        >
                          Afternoon
                        </label>
                      </div>
                      <div className="d-flex mb-8">
                        <Form.Check
                          inline
                          name="intake_evening"
                          checked={values.intake_evening}
                          id="intake_evening_checkbox"
                          onChange={handleChange}
                          type="checkbox"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        />
                        <label
                          htmlFor="intake_evening_checkbox"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        >
                          Evening
                        </label>
                      </div>
                      <div className="d-flex mb-8 fs-14">
                        <Form.Check
                          inline
                          id="intake_dinner_checkbox"
                          name="intake_night"
                          onChange={handleChange}
                          checked={values.intake_night}
                          type="checkbox"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        />
                        <label
                          htmlFor="intake_dinner_checkbox"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        >
                          Dinner
                        </label>
                      </div>
                    </div>
                    <div
                      className={`mt-2 ${
                        !isMobile ? "d-flex fs-14" : "fs-18"
                      } `}
                    >
                      <div className="d-flex mb-8 ">
                        <Form.Check
                          inline
                          name="food_option"
                          id="before_food_radio"
                          value="before_food"
                          onChange={handleChange}
                          type="radio"
                          checked={values.food_option === "before_food"}
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        />
                        <label
                          htmlFor="before_food_radio"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        >
                          Before Food
                        </label>
                      </div>{" "}
                      <div className={`d-flex mb-8 ${!isMobile && "ml-30"}`}>
                        <Form.Check
                          inline
                          name="food_option"
                          id="after_food_radio"
                          value="after_food"
                          onChange={handleChange}
                          checked={values.food_option === "after_food"}
                          type="radio"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        />
                        <label
                          htmlFor="after_food_radio"
                          className={`d-flex align-items-center m-0 ${
                            isMobile ? "fs-14" : "fs-18"
                          }`}
                        >
                          After Food
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                <textarea
                  style={{ height: "74px" }}
                  type="text"
                  class="form-control mt-12"
                  id="exampleInputPassword1"
                  name="additional_instruction"
                  value={values.additional_instruction}
                  onChange={handleChange}
                  placeholder={
                    radioButton === "diagnostics" || isDiagnostics
                      ? "Instructions (Optional)"
                      : "Custom  Frequency/ Additional Instructions"
                  }
                />
              </div>
            </Modal.Body>
            <Modal.Footer
              className="d-flex justify-content-evenly  border-0 mt-30 p-0 mb-4"
              style={{
                width: "70%",
                margin: "auto",
                backgroundColor: "#fbfcff",
              }}
            >
              {isEdit && (
                <>
                  <UtilityButton
                    btnName="Delete"
                    width={isMobile ? "110px" : "210px"}
                    height="56px"
                    onclick={handleDeleteMedicine}
                    bgColor="red"
                  />
                </>
              )}
              <UtilityButton
                btnName={isEdit ? "Save" : "Add"}
                width={isMobile ? "110px" : "210px"}
                height="56px"
                onclick={handleSubmit}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
