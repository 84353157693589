import UtilityButton from "components/buttons/UtilityButton";
import Radios from "components/form-radios/Radios";
import Mobileheader from "components/mobile-components/mobile-header/Mobileheader";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import user from "services/user";
import InputGroup from "react-bootstrap/InputGroup";

import Loader from "components/loader/Loader";
import { router } from "App";
import Container from "react-bootstrap/Container";

import { toast } from "react-toastify";
import {
  getsearchIdParam,
  searchRxMobileNumber,
} from "redux-store/slices/user";
import { useLocation } from "react-router-dom";
import { store } from "redux-store/store";

export default function SearchRx() {
  // const [mobileNumber, setMobileNumber] = useState("");
  const [existingMemberId, setExistingMemberId] = useState("");
  const [validMobileNumber, setValidMobileNumber] = useState(false);
  const location = useLocation;
  const [addMembers, setAddMembers] = useState(false);
  const dispatch = useDispatch();
  const { SEARCH_RX: getSearchRxLoading } = useSelector(
    (state) => state.loading
  );
  const { searchRxMobileNumber: mobileNumber } = useSelector(
    (state) => state.user
  );

  const data = useSelector((state) => state.user);
  const { searchRxuser } = data || {};
  const handleMobileChange = (e) => {
    dispatch(searchRxMobileNumber(e.target.value));

    if (e.target.value.length < 10) {
      setValidMobileNumber(true);
    } else {
      setValidMobileNumber(false);
    }
    if (e.target.value.length > 9) {
      user.searchRx(e.target.value);
    }
  };
  const handleSearchRx = () => {
    if (existingMemberId) {
      router.navigate(`/rx-history?memberId=${existingMemberId}`);
    } else {
      toast.error("Please Select a Member");
    }
  };
  useEffect(() => {
    store.dispatch(getsearchIdParam({ pathName: location.pathname }));
  }, []);
  return (
    <>
      <Container className="p-16 mt-20">
        <Mobileheader
          pageName="Search Rx"
          onClick={() => router.navigate("/home")}
        />

        <div className="mt-30 mb-20">
          <label className="fs-16 fw-400 mb-8">Patient Phone Number</label>

          <InputGroup style={{ height: "46px", flexWrap: "nowrap" }} size="lg">
            <InputGroup.Text
              id="inputGroup-sizing-lg"
              style={{ background: "#f5f6f6" }}
            >
              +91
            </InputGroup.Text>
            <Form.Control
              aria-label="Large"
              name="phoneNumber"
              inputMode="numeric"
              maxLength={10}
              type="text"
              autoComplete="off"
              aria-describedby="inputGroup-sizing-sm"
              value={Number(mobileNumber) || ""}
              style={{ borderLeft: "none", background: "#f5f6f6" }}
              onChange={handleMobileChange}
            />
          </InputGroup>
          {validMobileNumber && (
            <span className="red mt-1">
              {" "}
              Please Enter 10 digits Mobile Number{" "}
            </span>
          )}
        </div>
        {getSearchRxLoading && <Loader />}
        {searchRxuser?.length > 0 && mobileNumber.length == 10 ? (
          <>
            <label
              className="label lightgray fs-16 mb-14 fw-500 black"
              htmlFor="floatingPasswordCustom"
            >
              Existing Member(s)
            </label>
            <div
              className="d-flex justify-content-between flex-wrap "
              style={{ maxHeight: "200px", overflow: "scroll" }}
            >
              {searchRxuser?.length > 0 ? (
                searchRxuser?.map((users) => {
                  return (
                    <>
                      <div
                        onClick={() => setExistingMemberId(users.id)}
                        style={{ flex: "40%" }}
                      >
                        <Radios label={users?.patient_name} i={users?.id} />
                      </div>
                    </>
                  );
                })
              ) : (
                <p>Members Not gptnn</p>
              )}
            </div>
          </>
        ) : (
          <p
            className="d-flex justify-content-center"
            style={{ color: "#53545A" }}
          >
            {mobileNumber.length == 10 &&
              searchRxuser?.length === 0 &&
              !getSearchRxLoading &&
              "Patient Not Found"}
          </p>
        )}

        {!addMembers && (
          <Row
            className="d-flex justify-content-center mt-30"
            style={{
              position: "fixed",
              bottom: "3rem",
              width: "98%",
              marginBottom: "18px",
            }}
          >
            <UtilityButton
              btnName="Next"
              height="56px"
              fontSize="19px"
              disabled={searchRxuser?.length > 0 ? false : true}
              onClick={handleSearchRx}
              // onClick={() =>
              //   existingMemberId
              //     ? router.navigate(`/new-rx/${existingMemberId}`)
              //     : toast.error("Please Select the Member")
              // }
            />
          </Row>
        )}
      </Container>
    </>
  );
}
