import { AsyncPaginate } from "react-select-async-paginate";
import { loadOptions } from "./loadOptions";
import { useCallback, useState } from "react";
import useIsMobile from "hooks/useResize";

export default function PaginatedSelect({
  setSearchPageOptions,
  dropDownKey,
  setIsOpenDrugModal,
  setMedicineDetail,
  setIsDiagnostics,
  setMedInstruction,
  inputValue,
  setInputValue,
}) {
  const [value, setValue] = useState(null);

  const isMobile = useIsMobile();

  const defaultAdditional = {
    page: 1,
  };

  const onInputChange = useCallback((nextInputValue, { action }) => {
    if (action === "input-change") {
      setInputValue(nextInputValue);
    }
  }, []);
  const loadPageOptions = async (q, prevOptions, { page }) => {
    const { options, hasMore } = await loadOptions(
      q,
      page,
      setSearchPageOptions
    );
    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const customStyles = {
    // provide correct types here
    control: (provided, state: { isFocused: boolean }) => ({
      ...provided,
      borderRadius: "5px",
      border: "3px solid #cddef1",
      boxShadow: state.isFocused ? "0 0 0 2px #3699FF" : null,
      height: "44px",
      whiteSpace: "nowrap",
      fontSize: isMobile ? "13px" : "",
    }),
    option: (provided, state: { isFocused: boolean }) => ({
      ...provided,
      color: state.isFocused ? "black " : null,
      height: "44px",
      margin: "6px 0px",
      // fontWeight: "bold",
      backgroundColor: state?.data.is_diagnostic === 1 ? "#fff5f4" : "#f3fbfe",
    }),
  };

  return (
    <>
      <AsyncPaginate
        inputValue={inputValue}
        key={dropDownKey}
        isSearchable={true}
        debounceTimeout={300}
        additional={defaultAdditional}
        placeholder={
          isMobile
            ? "Medicines / Diagnostics (Type at least 4 letters)"
            : "Search Medicines / Diagnostics (Type at least 4 letters)"
        }
        value={value}
        loadOptions={loadPageOptions}
        onInputChange={onInputChange}
        styles={customStyles}
        formatOptionLabel={(data) => {
          return (
            <>
              <strong>{data.brand_name} </strong>
              {data.generic_name}
            </>
          );
        }}
        onChange={(data) => {
          // setValue(data);
          // setIsDiagnostics
          if (data.is_diagnostic === 1) {
            setMedInstruction({ brandShortName: data.label });
            setIsDiagnostics(true);
          }
          setIsOpenDrugModal(true);
          setMedicineDetail(data);
        }}
      />
    </>
  );
}
