import ClockIcon from "assets/svg/ClockIcon";
import React from "react";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import Loader from "components/loader/Loader";
import user from "services/user";
import { convertTimestampToReadableDate } from "utils/helper";
import { router } from "App";
import TopHeadingIcon from "components/WebComponet/TopHeadingIcon";
import { store } from "redux-store/store";
import {
  clearAddPrescriptionDetails,
  getsearchIdParam,
} from "redux-store/slices/user";
import { addPrescriptionDetails } from "redux-store/slices/user";
import { addDiagnosis } from "redux-store/slices/user";

export default function RxHistory() {
  const { rxHistory } = useSelector((state) => state.user);
  const location = useLocation();
  // getsearchIdParam
  const { GET_RX_HISTORY: getRxHistoryLoading } = useSelector(
    (state) => state.loading
  );

  const { rx_user, prescription } = rxHistory;

  const [searchParams] = useSearchParams();

  const memberId = searchParams.get("memberId");
  useEffect(() => {
    user.getRxHistory(memberId);
  }, []);

  useEffect(() => {
    const data = {
      pathName: location.pathname,
      memberId: memberId,
    };
    store.dispatch(getsearchIdParam(data));
  }, []);

  function fetchCopyRx(e, prescriptionMedicine, follow_up_advice) {
    e.stopPropagation();
    store.dispatch(clearAddPrescriptionDetails());
    prescriptionMedicine.forEach((item) => {
      let data = {
        brand_name_short: item.medicine.brand_name_short,
        dosage_duration: item.dosage_duration,
        additional_instruction: item?.additional_instruction,
        intake_morning: item?.intake_morning,
        intake_afternoon: item?.intake_afternoon,
        intake_evening: item?.intake_evening,
        intake_night: item?.intake_night,
        before_food: item?.before_food,
        after_food: item?.after_food,
        is_diagnostic: item.medicine.is_diagnostic,
      };

      store.dispatch(
        addPrescriptionDetails({
          id: memberId,
          diagnosis: "",
          medicalDetail: data,
        })
      );
      console.log(memberId, follow_up_advice, "DATAC");
      store.dispatch(
        addDiagnosis({ existingMemberId: memberId, value: follow_up_advice })
      );
    });

    router.navigate(`/new-rx/${memberId}`);
  }

  function openPrescriptionPdf(e, url) {
    e.stopPropagation();
    window.open(url, "_blank");
  }

  return (
    <>
      <Container className="mt-60 mb-4">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "94%", margin: "auto" }}
        >
          <p className="fs-32 fw-700">Rx History</p>
          <p className="fs-32 fw-700">
            <TopHeadingIcon
              width="34px"
              onClick={() => router.navigate("/search-rx")}
            />
          </p>
        </div>

        {getRxHistoryLoading ? (
          <Loader />
        ) : (
          <>
            <div
              className="d-flex justify-content-between "
              style={{
                width: "90%",
                margin: "30px auto",
                border: "1px solid #f1f1f2",
                padding: "22px 42px",
                borderRadius: "10px",
              }}
            >
              <div>
                <p className="m-0 fw-700 fs-24">{`${rx_user?.patient_name?.[0]?.toUpperCase()}${rx_user?.patient_name?.slice(
                  1
                )}`}</p>
                <p className="m-0 fw-400 fs-16 grey">
                  {rx_user?.gender[0]?.toUpperCase()}
                  {rx_user?.gender.slice(1)} /&nbsp;
                  {rx_user?.age}
                  years
                </p>
              </div>
              <div>
                <p className="fw-700 fs-16">
                  Rx ID &nbsp;
                  <span className="grey fw-400 fs-14">
                    {prescription?.[0]?.rx_id}
                  </span>
                </p>
              </div>
            </div>

            <div
              className="d-flex gap-3 flex-wrap"
              style={{
                width: "90%",
                margin: "auto",
                border: "1px solid #f1f1f2",
                borderRadius: "10px",
                padding: "28px 16px",
                maxHeight: "430px",
                overflow: "scroll",
              }}
            >
              {!getRxHistoryLoading && prescription?.length === 0 && (
                <div className="text-center fw-700 fs-26 w-100">
                  Data Not Found
                </div>
              )}

              {prescription?.map((item, index) => {
                return (
                  <>
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        router.navigate(
                          `/prescription?user_id=${item.user_id}?&prescription_id=${item.id}`
                        )
                      }
                      style={{
                        width: "32%",
                        background: "#fbfbfb",
                        borderRadius: "10px",
                        padding: "18px 11px",
                        border: "1px solid #e3eaee",
                      }}
                    >
                      <span className="fs-14 fw-700 m-0">
                        {item?.user?.full_name}
                      </span>
                      <span className="fs-14 fw-400 grey ml-6">
                        {item?.user?.degree_speciality}
                      </span>
                      <div className="d-flex  justify-content-between align-items-center">
                        <span
                          className="text-center fw-700"
                          style={{
                            display: "inline-block",
                            width: "25%",
                            backgroundColor: "pink",
                            fontSize: "12px",
                            padding: "8px",
                            borderRadius: "6px",
                            marginRight: "4px",
                          }}
                        >
                          {/* {item?.prescription_medicines?.[0]?.medicine
                              ?.createdAt} */}
                          {convertTimestampToReadableDate(
                            item?.prescription_medicines?.[0]?.createdAt
                          )}
                        </span>
                        <div
                          style={{
                            // marginTop: "2px",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "flex-end", // Align items to the right
                            gap: "10px", // Add space between buttons
                          }}
                        >
                          <button
                            className="fs-11 gray   fw-500 border-0 p-2"
                            variant="light"
                            style={{
                              color: "#19104e",
                              backgroundColor: "#d8f1fb",
                            }}
                            onClick={(event) =>
                              openPrescriptionPdf(event, item.pdf_url)
                            }
                          >
                            Open Rx
                          </button>
                          <button
                            className="fs-11 gray  p-2 fw-500 border-0"
                            variant="light"
                            style={{
                              color: "#19104e",
                              backgroundColor: "#d8f1fb",
                            }}
                            onClick={(e) =>
                              fetchCopyRx(
                                e,
                                item.prescription_medicines,
                                item.follow_up_advice
                              )
                            }
                          >
                            Copy Rx
                          </button>
                        </div>
                      </div>

                      <div className="mt-20">
                        <p className="fw-500 fs-14 m-0 ">
                          {`${item.prescription_medicines?.[0]?.medicine?.brand_name_short} ( ${item.prescription_medicines?.[0]?.medicine?.generic_name})`}
                        </p>
                      </div>

                      <span className="fs-12 mt-15">
                        <ClockIcon /> &nbsp;
                        {item?.prescription_medicines?.[0]?.dosage_duration && (
                          <span style={{ color: "#5e5992" }}>
                            {` (${item.prescription_medicines[0].dosage_duration})`}
                          </span>
                        )}
                        {(item?.prescription_medicines?.[0]?.intake_morning ===
                          1 ||
                          item?.prescription_medicines?.[0]
                            ?.intake_afternoon === 1 ||
                          item?.prescription_medicines?.[0]?.intake_evening ===
                            1 ||
                          item?.prescription_medicines?.[0]?.intake_night ===
                            1) && (
                          <>
                            <span style={{ color: "#5e5992" }}>
                              (
                              <span
                                className={`${"fs-12"} fw-400`}
                                style={{ color: "#5e5992" }}
                              >
                                {[
                                  item?.prescription_medicines?.[0]
                                    ?.intake_morning && "Morning",
                                  item?.prescription_medicines?.[0]
                                    ?.intake_afternoon && "Afternoon",
                                  item?.prescription_medicines?.[0]
                                    ?.intake_evening && "Evening",
                                  item?.prescription_medicines?.[0]
                                    ?.intake_night && "Night",
                                ]
                                  .filter(Boolean)
                                  .join(", ")}
                              </span>
                              )
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </Container>
    </>
  );
}
